import { useState, useEffect, useMemo } from 'react';
import useStore from 'store';
import dayjs from 'dayjs';

import { useQueryClient, useMutation } from 'react-query';
import * as authService from 'services/api/auth';
import { setAuthorizationHeader } from 'services/api/client';
import useTriggerTimestamp from 'hooks/use-trigger-timestamp';
import { ADMIN, SUPER_ADMIN, OWNER } from 'constants/users';
import EventHub from 'services/events';

function isAuthenticated(currentUser, expiresAt) {
  return currentUser && expiresAt > dayjs().valueOf();
}

function useProvideAuth() {
  const queryClient = useQueryClient();

  const currentUser = useStore(state => state.currentUser);
  const credentials = useStore(state => state.credentials);

  const storeSignin = useStore(state => state.signin);
  const storeSignout = useStore(state => state.signout);
  const clearNotifications = useStore(state => state.clearNotifications);

  const [isInitilized, setIsInitilized] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(isAuthenticated(currentUser, credentials?.expiresAt));

  useEffect(() => {
    const loginState = isAuthenticated(currentUser, credentials?.expiresAt);
    setLoggedIn(loginState);
    setAuthorizationHeader(loginState ? credentials?.token : null);
    if (loginState) {
      EventHub.setToken(credentials?.token);
    }
  }, [currentUser, credentials]);

  useEffect(() => {
    setIsInitilized(true);
  }, []);

  const useSuperAdmin = useMemo(
    () => window.location.hostname.startsWith('superadmin') && currentUser?.role === SUPER_ADMIN,
    [currentUser],
  );

  const isAdmin = useMemo(
    () => currentUser?.role === OWNER || currentUser?.role === ADMIN || currentUser?.role === SUPER_ADMIN,
    [currentUser],
  );

  const isOwner = useMemo(
    () => currentUser?.role === OWNER || currentUser?.role === SUPER_ADMIN,
    [currentUser],
  );

  const isSuperAdmin = useMemo(
    () => currentUser?.role === SUPER_ADMIN,
    [currentUser],
  );

  const signinMutation = useMutation(
    ({ email, password }) => authService.login(email, password),
    {
      onSuccess: ({ headers, data }) => {
        EventHub.setToken(headers['api-token']);
        storeSignin({
          id: data.id,
          role: data.role,
        }, headers['api-token'], dayjs(headers['api-token-expiresat']).valueOf());
      },
    },
  );

  const requestPasswordResetMutation = useMutation(
    ({ email }) => authService.requestResetPassword(email),
  );

  const signin = async (email, password) => {
    signinMutation.mutate({ email, password });
  };

  const signout = async () => {
    storeSignout();
    clearNotifications();
    await authService.logout(currentUser.id);
    EventHub.close();
    queryClient.invalidateQueries();
  };

  const requestPasswordReset = async (email) => {
    requestPasswordResetMutation.mutate({ email });
  };

  useTriggerTimestamp(signout, credentials?.expiresAt - 10000 || null);

  const verifyMutation = useMutation(
    ({ userId, password, signature }) => authService.verifyUser(userId, password, signature),
    {
      onSuccess: ({ data }, payload) => {
        signinMutation.mutate({
          email: data.email,
          password: payload.password,
        });
      },
    },
  );

  const verify = async (userId, password, signature) => {
    verifyMutation.mutate({ userId, password, signature });
  };

  const authSignature = useMutation(
    () => authService.generateAuthSignature(),
  );

  const resumeMutation = useMutation(
    ({ userId, signature }) => authService.resume(userId, signature),
    {
      onSuccess: ({ headers, data }) => {
        EventHub.setToken(headers['api-token']);
        storeSignin({
          id: data.id,
          role: data.role,
        }, headers['api-token'], dayjs(headers['api-token-expiresat']).valueOf());
      },
    },
  );

  const resume = async (userId, signature) => {
    resumeMutation.mutate({ userId, signature });
  };

  return {
    isInitilized,
    isLoggedIn,
    isAdmin,
    isOwner,
    isSuperAdmin,
    currentUser,
    useSuperAdmin,
    signinMutation,
    verifyMutation,
    resumeMutation,
    requestPasswordResetMutation,
    authSignature,
    signin,
    signout,
    requestPasswordReset,
    verify,
    resume,
  };
}

export default useProvideAuth;
